import React from "react";
import "./TeamComponent.css";
import whitecort from "../../img/white-coat.png"
import fb from "../../img/bx-_fb.png"
import insta from "../../img/bxl_insta.png"
import linkdin from "../../img/bxl_linkdine.png"
import nurse from "../../img/nurse.png"
import dental from "../../img/dental.png"
import whtDental from "../../img/white-dental.png"
import blog from "../../img/blog-icon.png"
import { Link } from "react-router-dom";

const TeamComponent = () => {
  return (
    <section className="specialist position-relative">
      <div className="container-1240">
        <div className="row">
          <div className="col-lg-12">
            <div className="sub-heading position-relative">
              <h4 className="text-dark fst-normal">Meet Our Specialist</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-7">
            <h2 className="expert fst-normal">We have expert doctors team</h2>
            <p className="para text-dark fw-normal">
              Lorem Ipsum is simy & dummy text of the printing & it typesetting
              postpond. Lorem Ipsum has been the industriesthe printing.
            </p>
          </div>
          <div className="col-lg-5">
            <div className="member-btn">
              <Link to="/Home/Our_Team" className="custom-btn btn-1 text-white">
                Becaome A Member
              </Link>
            </div>
          </div>
        </div>
        <div className="row gy-3">
          <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6">
            <div className="doctor-info-box">
              <div className="doct-image text-center position-relative">
                <div className="color-overlay d-none"></div>
                <img src={whitecort} alt="" className="special-doctr" />
                <div className="doctor-social-media d-none">
                  <Link to="#" className="active">
                    <img src={fb} alt="" />
                  </Link>
                  <Link to="#">
                    <img src={insta} alt="" />
                  </Link>
                  <Link to="#">
                    <img src={linkdin} alt="" />
                  </Link>
                </div>
              </div>
              <div className="doctor-info text-center">
                <h3 className="text-center">David Corner</h3>
                <h6 className="text-center">Heart Specialist</h6>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6">
            <div className="doctor-info-box">
              <div className="doct-image text-center">
                <div className="color-overlay d-none"></div>
                <img src={nurse} alt="" className="special-doctr" />
                 <div className="doctor-social-media d-none">
                  <Link to="#" className="active">
                    <img src={fb} alt="" />
                  </Link>
                  <Link to="#">
                    <img src={insta} alt="" />
                  </Link>
                  <Link to="#">
                    <img src={linkdin} alt="" />
                  </Link>
                </div>
              </div>
              <div className="doctor-info text-center">
                <h3 className="text-center">Alexa Jems</h3>
                <h6 className="text-center">Eye Specialist</h6>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6">
            <div className="doctor-info-box">
              <div className="doct-image text-center">
                <div className="color-overlay d-none"></div>
                <img src={dental} alt="" className="special-doctr" />
                 <div className="doctor-social-media d-none">
                  <Link to="#" className="active">
                    <img src={fb} alt="" />
                  </Link>
                  <Link to="#">
                    <img src={insta} alt="" />
                  </Link>
                  <Link to="#">
                    <img src={linkdin} alt="" />
                  </Link>
                </div>
              </div>
              <div className="doctor-info text-center">
                <h3 className="text-center">David Corner</h3>
                <h6 className="text-center">Dentist</h6>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6">
            <div className="doctor-info-box">
              <div className="doct-image text-center">
                <div className="color-overlay d-none"></div>
                <img src={whtDental} alt="" className="special-doctr" />
                 <div className="doctor-social-media d-none">
                  <Link to="#" className="active">
                    <img src={fb} alt="" />
                  </Link>
                  <Link to="#">
                    <img src={insta} alt="" />
                  </Link>
                  <Link to="#">
                    <img src={linkdin} alt="" />
                  </Link>
                </div>
              </div>
              <div className="doctor-info text-center">
                <h3 className="text-center">Sasha brian</h3>
                <h6 className="text-center">Medical Chif</h6>
              </div>
            </div>
          </div>
        </div>
        <Link to="/Home/Our_Team">
        <div className="explore-btn">
          View All Member
          <Link className="learn-more" to="#">
            <img src={blog} alt="" className="up" />
          </Link>
        </div>
        </Link>
      </div>
    </section>
  );
};

export default TeamComponent;
