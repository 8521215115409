import React from 'react'
import Header from '../../Components/Header/Header'
import BannerGlobal from '../../Components/Banner/BannerGlobal'
import Footer from '../../Components/Footer/Footer'
import BlogComponent from '../../Components/BlogComponent/BlogComponent'
import BlogComponent2 from '../../Components/BlogComponent/BlogComponent2'
import useDocumentTitle from '../../PageTitle'

const Blogs = () => {
  useDocumentTitle("Medical | Blogs")
  return (
    <div className='blogPage'>
        <Header/>
        <BannerGlobal/>
        <BlogComponent/>
        <BlogComponent2/>
        <Footer/>
    </div>
  )
}

export default Blogs