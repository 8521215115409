import React from 'react'
import Header from '../../Components/Header/Header'
import Footer from '../../Components/Footer/Footer'
import ErrorPage from '../../Components/ErrorPage/ErrorPage'
import useDocumentTitle from '../../PageTitle'

const Error404 = () => {
  useDocumentTitle("Medical | Error 404")
  return (
    <div>
        <Header/>
        <ErrorPage/>
        <Footer/>
    </div>
  )
}

export default Error404