import React from 'react'
import apportment from "../../img/Image (4).png"

const ContactComponent3 = () => {
  return (
<section className="get-in-touch">
    <div className="container-1240">
      <div className="row gy-3">
        <div className="col-lg-5">
          <div className="contact-img about-img">
            <img src={apportment} alt="apportment" />
          </div>
        </div>
        <div className="col-lg-7">
          <form className="contact-form row g-3">
            <div className="msg-title">
              <h3>Start Your Appointment Form</h3>
              <p>Lorem Ipsum is simply & dummy text of the printing & it a typesetting industry.</p>
            </div>
            <div className="col-md-6">
              <label for="inputEmail4" className="form-label">Select Department *</label>
              <input type="email" className="form-control" id="inputEmail4" placeholder="John Doe"/>
            </div>
            <div className="col-md-6">
              <label for="inputPassword4" className="form-label">Choose Doctor *</label>
              <input type="password" className="form-control" id="inputPassword4" placeholder="johndoe123@email.com" />
            </div>
            <div className="col-md-6">
              <label for="inputEmail4" className="form-label">Patient name *</label>
              <input type="email" className="form-control" id="inputEmail4" placeholder="John Doe"/>
            </div>
            <div className="col-md-6">
              <label for="inputPassword4" className="form-label">Phone *</label>
              <input type="password" className="form-control" id="inputPassword4" placeholder="johndoe123@email.com"/>
            </div>
            <div className="col-md-6">
              <label for="inputEmail4" className="form-label">Email *</label>
              <input type="email" className="form-control" id="inputEmail4" placeholder="John Doe"/>
            </div>
            <div className="col-md-6">
              <label for="inputPassword4" className="form-label">City</label>
              <input type="password" className="form-control" id="inputPassword4" placeholder="johndoe123@email.com"/>
            </div>
            <div className="col-md-6">
              <label for="inputAddress" className="form-label">Date *</label>
              <input type="date" className="form-control" id="inputAddress" placeholder="09/07/22"/>
            </div>
            <div className="col-md-6">
              <label for="inputState" className="form-label">Time *</label>
              <input type="time" className="form-control" id="inputAddress" placeholder="+44 123 456 0789"/>
            </div>
            <label for="exampleFormControlTextarea1" className="form-label">Massage*</label>
            <textarea className="form-control" id="exampleFormControlTextarea1" rows="3"
              placeholder="Write a message........."></textarea>
            <div className="col-12">
              <button type="submit" className="btn form-submit">submit form</button>
            </div>
          </form>
        </div>
      </div>
    </div>

  </section>
  )
}

export default ContactComponent3