import React from "react";
import Header from "../../Components/Header/Header";
import BannerGlobal from "../../Components/Banner/BannerGlobal";
import Footer from "../../Components/Footer/Footer";
import ServiceComponent from "../../Components/ServiceComponent/ServiceComponent";
import HighlightComponent from "../../Components/HighlightComponent/HighlightComponent";
import TeamComponent from "../../Components/TeamComponent/TeamComponent";
import ReviewComponent from "../../Components/ReviewComponent/ReviewComponent";
import ContactComponent from "../../Components/ContactComponent/ContactComponent";
import useDocumentTitle from "../../PageTitle";

const Service = () => {
  useDocumentTitle("Medical | Services")
  return (
    <div>
      <Header />
      <BannerGlobal />
      <ServiceComponent />
      <HighlightComponent />
      <TeamComponent />
      <ReviewComponent />
      <ContactComponent />
      <Footer />
    </div>
  );
};

export default Service;
