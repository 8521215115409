import React from "react";
import "./CaseStudyComponent.css";
import blogIcon from "../../img/blog-icon.png";
import { CaseStudyData } from "./CaseStudyData";
import { Link, createSearchParams } from "react-router-dom";


const CaseStudyComponent = () => {
  return (
    <section className="pages-specialist">
      <div className="container-1240">
        <div className="row gy-4">
          {
            CaseStudyData.map((e,i)=>
            <div className="col-xl-4 col-lg-6 col-md-6" key={i}>
          <Link to={`/Home/Case_Study/Case_Study_Detail?${createSearchParams({id : e.id})}`}>
          <div className="pages-info-box">
              <div className="doct-image text-center position-relative">
                <img src={e.img} alt="caseStudy1" />
              </div>
              <div className="doctor-info text-center">
                <h4 className="text-dark text-center">
                  {e.name}
                </h4>
              </div>
            </div>
          </Link>
          </div>
            )
          }
          
        </div>
        <div className="explore-btn">
          All Case Studies
          <Link href="#">
            <img src={blogIcon} alt="" className="up" />
          </Link>
        </div>
      </div>
    </section>
  );
};

export default CaseStudyComponent;
