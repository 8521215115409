import React from 'react'
import Header from '../../Components/Header/Header'
import BannerGlobal from '../../Components/Banner/BannerGlobal'
import Footer from '../../Components/Footer/Footer'
import TestimonialComponent from '../../Components/ReviewComponent/TestimonialComponent'
import ContactComponent from '../../Components/ContactComponent/ContactComponent'
import useDocumentTitle from '../../PageTitle'

const Testimonial = () => {
  useDocumentTitle("Medical | Testimonials")
  return (
    <div>
        <Header/>
        <BannerGlobal/>
        <TestimonialComponent/>
        <ContactComponent/>
        <Footer/>
    </div>
  )
}

export default Testimonial