import React from 'react'
import "./ErrorPage.css"
import { Link } from 'react-router-dom'

const ErrorPage = () => {
  return (
    <>
     <section className="erro-banner" id="erro-banner">
    <div className="container-1240">
      <div className="row">
        <div className="col-lg-12">
          <div className="error-text">
            <span className="error-title"> 404 </span>
            <h2>The page you were looking for<br/>
              does not exist. </h2>
            <div className="error-btn">
              <Link to="/Home" className="go-btn text-white">Go to Homepage</Link>
              <Link to="/Home/Contact_Us" className="contact-btn text-dark">Contact Us</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="appointment-today" id="appointment-today">
    <div className="container-1240">
      <div className="bg-today">
        <div className="inner-bg-today d-flex justify-content-between align-items-center flex-wrap">
          <div className="left-title w-60">
            <h2>Schedule an Appointment Today.</h2>
            <p>Nulla pretium eros nec ante interdum mattis. Donec ac bibendum
              urna. Fusce nec lorem non leo efficitur semper. </p>
          </div>
          <div className="right-btn w-40 d-flex justify-content-end">
            <a href="#" className="an-appt">Make an Appointment</a>
          </div>
        </div>
      </div>
    </div>
  </section>
    </>
  )
}

export default ErrorPage