import React from "react";
import "./BlogComponent.css";
import { BlogData } from "./BlogData";
import { Link, createSearchParams } from "react-router-dom";

const BlogComponent = () => {
  return (
    <section className="medicald-blog">
      <div className="container-1240">
        <div className="row blogHeading">
          <div className="col-lg-12">
            <div className="sub-heading position-relative">
              <h4 className="text-dark fst-normal">Increase Your Knowledge</h4>
            </div>
          </div>
        </div>
        <div className="row blogHeading">
          <div className="col-lg-7">
            <h2 className="expert fst-normal">Latest Medical blogs</h2>
            <p className="top-para text-dark fw-normal">
              Lorem Ipsum is simy & dummy text of the printing & it typesetting
              postpond.Lorem Ipsum has been the industriesthe printing.
            </p>
          </div>
          <div className="col-lg-5">
            <div className="member-btn">
              <Link to="/Home/Our_Blogs" className="text-white">
                View All Blogs
              </Link>
            </div>
          </div>
        </div>
        <div className="row ">
          <div className="col-lg-6">
            {BlogData.slice(0, 1).map((e, i) => (
              <div className="blog-left">
                <div className="women-medical">
                  <img src={e.img} alt="wommen-medical" />
                </div>
                <div className="medical-text bg-white">
                  <h4>{e.name} </h4>
                  <p>{e.para} </p>
                  <div className="read-more">
                    <Link to={`/Home/Our_Blogs/Blog_Details?${createSearchParams({id : e.id})}`}>
                      Read more <img src={e.arrow} alt="" className="up" />
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="col-lg-6">
            <div className="blog-right">
              {BlogData.slice(1, 4).map((e, i) => (
                <Link to={`/Home/Our_Blogs/Blog_Details?${createSearchParams({id : e.id})}`}>
                <div className="blog-wrap d-flex">
                  <div className="blog-post">
                    <img src={e.img} alt="blog2" />
                    <div className="date-btn">{e.date} </div>
                  </div>
                  <div className="blog-post-content">
                    <div className="blog-text">{e.name}</div>
                    <p>{e.para} </p>
                  </div>
                </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogComponent;
