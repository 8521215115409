import React from "react";
import "./CommingSoonComp.css";
import person from "../../img/person-prof.png";

const CommingSoonComp = () => {
  return (
    <>
      <section
        className="about-banner"
        id="banner"
        style={{ paddingTop: "149px", paddingBottom: "84px" }}
      >
        <div className="container-1240"></div>
      </section>
      <section className="comming-soon" id="comming-soon">
        <div className="container-1240">
          <div className="row">
            <div className="col-xl-8 col-lg-8 col-md-6 col-sm-12">
              <div className="text-left-comming">
                <div className="comming-soon-text"> We Are Coming Soon </div>
                <div className="our-best">
                  <h3>We are doing our best to launch asap</h3>
                </div>
                <div className="subscribe-text text-dark">
                  Subscribe to get notifed
                </div>
                <form className="row align-items-center">
                  <div className="col-lg-12">
                    <div className="email-form position-relative">
                      <input
                        type="email"
                        className="form-control"
                        id="inputEmail4"
                        placeholder="Email"
                      />
                      <button type="submit" className="btn submit-btn">
                        Subscribe
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-4 col-lg-4 col-md-6 col-sm-12">
              <div className="profess-img">
                <img src={person} alt="person" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CommingSoonComp;
