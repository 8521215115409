import React from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import BannerGlobal from "../../Components/Banner/BannerGlobal";
import ServiceDetailsCompo from "../../Components/ServiceComponent/ServiceDetailsCompo";
import TeamComponent from "../../Components/TeamComponent/TeamComponent";
import ReviewComponent from "../../Components/ReviewComponent/ReviewComponent";
import ContactComponent from "../../Components/ContactComponent/ContactComponent";
import useDocumentTitle from "../../PageTitle";

const ServiceDetails = () => {
  useDocumentTitle("Medical | Service Details")
  return (
    <div className="blogDetails">
      <Header />
      <BannerGlobal />
      <ServiceDetailsCompo />
      <TeamComponent />
      <ReviewComponent />
      <ContactComponent />
      <Footer />
    </div>
  );
};

export default ServiceDetails;
