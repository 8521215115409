import React from "react";
import "./ServiceComponent.css";
import blogIcon from "../../img/blog-icon.png";
import { ServiceData } from "./ServiceData";
import { Link, createSearchParams } from "react-router-dom";

const ServiceComponent = () => {
  return (
    <section className="service">
      <div className="container-1240">
        <div className="row">
          <div className="col-lg-12">
            <div className="position-relative text-center">
              <h4 className="text-dark fw-normal">
                <span>
                  <img src="img/line-h4.png" alt="" />
                </span>
                Our Medical Services
              </h4>
            </div>
            <div className="medium-heading offer text-center">
              <h2 className="text-dark fst-normal">We offer special services</h2>
            </div>
            <div className="service-para text-center">
              <p className="text-dark">
                Lorem Ipsum is simy & dummy text of the printing & it
                typesetting postpond. Lorem Ipsum has been the industriesthe
                printing.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          {ServiceData.map((e, i) => (
            <div className="col-xl-4 col-lg-6 mb-4 col-md-6 col-sm-12">
              <div className="social-box">
                <div className="inner-content">
                  <div className="custmor-serv-img">
                    <img src={e.img} alt="icon1" />
                  </div>
                  <h4 className="fw-normal">{e.name} </h4>
                  <p className="fw-normal">{e.para}</p>
                  <Link to={`/Home/Our_Services/Service_Details?${createSearchParams({id : e.id})}`} className="learn-more">
                    Learn more <img src={e.arrow} alt="" className="up" />
                  </Link>
                </div>
              </div>
            </div>
          ))}
          
        </div>
       <Link to="/Home/Our_Services">
       <div className="explore-btn">
          Explore All Services
          <a className="learn-more">
            <img src={blogIcon} alt="" className="up" />
          </a>
        </div>
       </Link>
      </div>
    </section>
  );
};

export default ServiceComponent;
