import React, { useEffect, useState } from "react";
import "./Banner.css";
import icon from "../../img/icon (21).png";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { ServiceData } from "../ServiceComponent/ServiceData";
import { BlogData } from "../BlogComponent/BlogData";
import { CaseStudyData } from "../CaseStudyComponent/CaseStudyData";
import { HeaderData } from "../Header/HeaderData";

const BannerGlobal = () => {
  const [heading, setHeading] = useState();
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  let pathName = location.pathname;

  useEffect(() => {
    const pathnamee = location.pathname.split("/").filter((p) => !!p);
    const breadcrumbs = pathnamee.map((e, i) => ({
      title: e,
      path: `/${pathnamee.slice(0, i + 1).join("/")}`,
    }));
    setBreadcrumbs(breadcrumbs);
  }, [location]);

  useEffect(() => {
    const id = searchParams.get("id");
    if (id) {
      if (pathName.includes("Service_Details")) {
        const sData = ServiceData.find((e) => e.id == id);
        setHeading(sData.name);
      }
      if (pathName.includes("Blog_Details")) {
        const bData = BlogData.find((e) => e.id == id);
        setHeading(bData.name);
      }
      if (pathName.includes("Case_Study_Detail")) {
        const wData = CaseStudyData.find((e) => e.id == id);
        setHeading(wData.name);
      }
    } else {
      const data = HeaderData.find((data) => {
        if (data?.subNav) {
          const subTitel = data?.subNav?.find((s) => s.path === pathName);
          if (subTitel) {
            return data;
          }
        } else if (data.path === pathName) {
          return data;
        }
      });

      if (data?.subNav) {
        const obj = data?.subNav.find((s) => s.path === pathName);
        setHeading(obj.heading);
      } else {
        setHeading(data.heading);
      }
    }
  }, []);

  return (
    <section className="about-banner" id="banner">
      <div className="container-1240">
        <div className="row">
          <div className="col-xl-6 col-lg-12 col-md-12">
            <div className="banner-text-wrap">
              <h3 className="text-white fw-normal">{heading} </h3>
              <div className="banner-menu">
                <ol className="breadcrumb">
                  {breadcrumbs.map((breadcrumb, index) => (
                    <li
                      className={`breadcrumb-item${
                        index === breadcrumbs.length - 1 ? " active" : ""
                      }`}
                    >
                      <Link key={index} to={breadcrumb.path} class="text-white">
                        {breadcrumb.title.replace(/_/g, " ")}
                      </Link>
                    </li>
                  ))}
                </ol>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-12 col-md-12">
            <div className="about-talk-box d-flex align-items-center">
              <div className="mobile-img">
                <img src={icon} alt="msg" />
              </div>
              <div className="contact-info">
                <h6 className="fw-normal text-dark">Call Us on</h6>
                <p className="fw-normal">+44 123 456 7890</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BannerGlobal;
