import React, { useEffect, useRef } from "react";
import "./Header.css";
import msg from "../../img/massenger.png";
import logo from "../../img/troo-logo.png";
import {
  FaTwitter,
  FaFacebookF,
  FaLinkedinIn,
  FaInstagram,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import Navmenu from "./NavMeanu";
import Navbar from "react-bootstrap/Navbar";
import { HeaderData } from "./HeaderData";

const Header = () => {
  const navbar_ref = useRef();
  function handleScroll() {
    if (window.pageYOffset > 500 || document.documentElement.scrollTop > 150) {
      if (navbar_ref.current) {
        navbar_ref.current.style.top = "0px";
      }
    } else {
      if (navbar_ref.current) {
        navbar_ref.current.style.top = "-150px";
      }
    }
  }
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <header>
      <section id="header" ref={navbar_ref} class="show_nav">
        <div class="container-1240">
          <Navbar expand="lg">
            <Navbar.Brand>
              <Link to="/">
                <img src={logo} alt="logo" />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <ul className="navbar-nav">
                {HeaderData.slice(0, 6).map((item, i) => {
                  return <Navmenu key={i} item={item} />;
                })}
              </ul>
              <div className="talk-box d-flex align-items-center">
                <div className="massage-img">
                  <img src={msg} alt="msg" />
                </div>
                <div className="massage-info">
                  <p className="font16 fw-normal text-white"> +44 123 456 7890</p>
                  <p className="font16 fw-normal text-white">
                    troomedical@email.com
                  </p>
                </div>
              </div>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </section>
      <section id="top-bar" className="top-bar">
        <div className="container-1240">
          <div className="row gy-3 align-items-center">
            <div className="col-xl-6 col-lg-4 col-md-6 col-sm-12">
              <div className="social-media d-flex align-items-center">
                <div className="follow-us">
                  <h6 className="text-white">Follow Us</h6>
                </div>
                <div className="social-icon px-2">
                  <Link to="#">
                    <FaFacebookF />
                  </Link>
                  <Link to="#">
                    <FaTwitter />
                  </Link>
                  <Link to="#">
                    <FaLinkedinIn />
                  </Link>
                  <Link to="#">
                    <FaInstagram />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <div className="email text-white">Email us : &nbsp;
                <a href="#" className="emailid">trootheme@medical.com</a>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <div className="appointment-btn text-center">
                {HeaderData.slice(-1).map((e, i) => (
                  <Link to={e.path} key={i} className="text-white">
                    {e.title}
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="header" className="header">
        <div className="container-1240">
          <Navbar expand="lg">
            <Navbar.Brand>
              <Link to="/">
                <img src={logo} alt="logo" />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <ul className="navbar-nav">
                {HeaderData.slice(0, 6).map((item, i) => {
                  return <Navmenu key={i} item={item} />;
                })}
              </ul>
              <div className="talk-box d-flex align-items-center">
                <div className="massage-img">
                  <img src={msg} alt="msg" />
                </div>
                <div className="massage-info">
                  <p className="font16 fw-normal text-white">+44 123 456 7890</p>
                  <p className="font16 fw-normal text-white">
                    troomedical@email.com
                  </p>
                </div>
              </div>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </section>
    </header>
  );
};

export default Header;
