import blog1 from "../../img/blog1.png";
import blog2 from "../../img/blog2.png";
import blog3 from "../../img/blog3.png";
import blog4 from "../../img/blog4.png";
import blog5 from "../../img/blog-1.png"
import blog6 from "../../img/blog-2.png"
import blog7 from "../../img/blog-3.png"
import blog8 from "../../img/blog-4.png"
import blogIcon from "../../img/blog-icon.png";

export const BlogData = [
  {
    id: 1,
    name: "How good is your cardiometabolic health & what is that, anyway?",
    img: blog1,
    para: "Lorem Ipsum is simy & dummy text of the printing & it typesetting postpond. Lorem Ipsum has been the industriesthe printing.",
    arrow: blogIcon,
  },
  {
    id: 2,
    name: "Beware Of Urological Emergency Patient’s Perspective ",
    img: blog2,
    para: "Lorem Ipsum is simy & dummy text of the printing....",
    date: "20 Aug, 2022",
  },
  {
    id: 3,
    name: "Beware Of Urological Emergency Patient’s Perspective ",
    img: blog3,
    para: "Lorem Ipsum is simy & dummy text of the printing....",
    date: "20 Aug, 2022",
  },
  {
    id: 4,
    name: "Beware Of Urological Emergency Patient’s Perspective ",
    img: blog4,
    para: "Lorem Ipsum is simy & dummy text of the printing....",
    date: "20 Aug, 2022",
  },
  {
    id: 5,
    name: "Beware Of Urological Emergency Patient’s Perspective ",
    img: blog5,
    para: "Lorem Ipsum is simy & dummy text of the printing....",
    date: "20 Aug, 2022",
  },
  {
    id: 6,
    name: "Beware Of Urological Emergency Patient’s Perspective ",
    img: blog6,
    para: "Lorem Ipsum is simy & dummy text of the printing....",
    date: "20 Aug, 2022",
  },
  {
    id: 7,
    name: "Beware Of Urological Emergency Patient’s Perspective ",
    img: blog7,
    para: "Lorem Ipsum is simy & dummy text of the printing....",
    date: "20 Aug, 2022",
  },
  {
    id: 8,
    name: "How good is your cardiometabolic health & what is that, anyway?",
    img: blog8,
    para: "Lorem Ipsum is simy & dummy text of the printing & it typesetting postpond. Lorem Ipsum has been the industriesthe printing.",
    arrow: blogIcon,
  },
];
