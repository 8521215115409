import React from "react";
import "./FAQComponent.css";
import line from "../../img/line-h4.png"
import blog from "../../img/blog-icon.png"
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";

const FAQComponent = () => {
  return (
    <section className="accourdian position-relative" id="accourdian">
      <div className="container-1240">
        <div className="row">
          <div className="col-lg-12">
            <div className="position-relative text-center">
              <h4 className="text-dark fw-normal">
                <span>
                  <img src={line} alt="" />
                </span>
                Our FAQ’s
              </h4>
            </div>
            <div className="medium-heading offer text-center">
              <h2 className="text-dark fst-normal">Have you any questions?</h2>
            </div>
            <div className="service-para text-center">
              <p className="text-dark">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                  How do I register an appointment?
                  </Accordion.Header>
                  <Accordion.Body>
                  Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    pain dummy text ever since the 1500s, when an unknown
                    printer
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                  What happens if my device is not working or there is a
                    problem logging in to the application?
                  </Accordion.Header>
                  <Accordion.Body>
                  Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    pain dummy text ever since the 1500s, when an unknown
                    printer
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                  Can I choose my doctor for consultation?
                  </Accordion.Header>
                  <Accordion.Body>
                  Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    pain dummy text ever since the 1500s, when an unknown
                    printer
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                  Is there a restriction on what type of patient can avail
                    tele-consultation services?
                  </Accordion.Header>
                  <Accordion.Body>
                  Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    pain dummy text ever since the 1500s, when an unknown
                    printer
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                  What is your refund policy?
                  </Accordion.Header>
                  <Accordion.Body>
                  Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    pain dummy text ever since the 1500s, when an unknown
                    printer
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header>
                  What kind of conditions are suitable for video consultation?
                  </Accordion.Header>
                  <Accordion.Body>
                  Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    pain dummy text ever since the 1500s, when an unknown
                    printer
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
          </div>
        </div>
       <Link to="/Home/FAQ">
       <div className="explore-btn">
          <span style={{color:"#000"}}> Don't Find Answers? </span> View All FAQ’s
          <a className="learn-more" href="#">
            <img src={blog} alt="" className="up" />
          </a>
        </div>
       </Link>
      </div>
    </section>
  );
};

export default FAQComponent;
