import React from "react";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import BannerGlobal from "../Components/Banner/BannerGlobal";
import ContactComponent2 from "../Components/ContactComponent/ContactComponent2";
import useDocumentTitle from "../PageTitle";

const Contact = () => {
  useDocumentTitle("Medical | Contacts")
  return (
    <div>
      <Header />
      <BannerGlobal />
      <ContactComponent2 />
      <Footer />
    </div>
  );
};

export default Contact;
