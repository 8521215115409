import React from "react";
import Header from "../Components/Header/Header";
import Banner from "../Components/Banner/Banner";
import Footer from "../Components/Footer/Footer";
import VideoSection from "../Components/VideoSection/VideoSection";
import AboutComponent from "../Components/AboutComponent/AboutComponent";
import ServiceComponent from "../Components/ServiceComponent/ServiceComponent";
import HighlightComponent from "../Components/HighlightComponent/HighlightComponent";
import TeamComponent from "../Components/TeamComponent/TeamComponent";
import FAQComponent from "../Components/FAQComponent/FAQComponent";
import ReviewComponent from "../Components/ReviewComponent/ReviewComponent";
import ContactComponent from "../Components/ContactComponent/ContactComponent";
import BlogComponent from "../Components/BlogComponent/BlogComponent";

const Home = () => {
  return (
    <div>
      <Header />
      <Banner />
      <VideoSection />
      <AboutComponent />
      <ServiceComponent />
      <HighlightComponent />
      <TeamComponent />
      <FAQComponent />
      <ReviewComponent />
      <ContactComponent/>
      <BlogComponent/>
      <Footer />
    </div>
  );
};

export default Home;
