import React from "react";
import "./ReviewComponent.css";
import line4 from "../../img/line-h4.png"
import review1 from "../../img/review-1.png"
import review2 from "../../img/review-2.png"
import review3 from "../../img/review-3.png"
import colon from "../../img/colon-img.png"
import colon1 from "../../img/colon1-img.png"
import blog from "../../img/blog-icon.png"
import { Link } from "react-router-dom";

const ReviewComponent = () => {
  return (
    <section className="review bg-white" id="review">
      <div className="container-1240">
        <div className="row">
          <div className="col-lg-12">
            <div className="patient-text position-relative text-center">
              <h4 className="text-dark fw-normal">
                <span>
                  <img src={line4} alt="" />
                </span>
                Patients Review
              </h4>
            </div>
            <div className="medium-heading offer text-center">
              <h2 className="text-dark fst-normal">
                What our patients say about us
              </h2>
            </div>
            <div className="service-para text-center">
              <p className="text-dark">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text.
              </p>
            </div>
          </div>
        </div>
        <div className="blog-box">
          <div className="row gy-3">
            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
              <div className="blog-bg text-center">
                <div className="admin text-center">
                  <img src={review1} alt="" />
                </div>
                <div className="blog-para text-center">
                  <p>
                    Lorem Ipsum is simply & dummy text of the printing & it a
                    typesetting industry. Lorem Ipsum has been the & industry's
                    standard dummy text.
                  </p>
                </div>
                <div className="blog-colon text-center d-block">
                  <img src={colon} alt="" />
                </div>
                <div className="blog-colon1 text-center d-none">
                  <img src={colon1} alt="" />
                </div>
                <div className="blog-read text-center">
                  <h4 className="text-dark">Bhushan Borse</h4>
                  <p className="text-dark">Heart Patient</p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
              <div className="blog-bg text-center">
                <div className="admin text-center">
                  <img src={review2} alt="" />
                </div>
                <div className="blog-para text-center">
                  <p>
                    Lorem Ipsum is simply & dummy text of the printing & it a
                    typesetting industry. Lorem Ipsum has been the & industry's
                    standard dummy text.
                  </p>
                </div>
                <div className="blog-colon text-center d-block">
                  <img src={colon} alt="" />
                </div>
                <div className="blog-colon1 text-center d-none">
                  <img src={colon1} alt="" />
                </div>
                <div className="blog-read text-center">
                  <h4 className="text-dark">Ravina Patel</h4>
                  <p className="text-dark">Cancer Patient</p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
              <div className="blog-bg text-center">
                <div className="admin">
                  <img src={review3} alt="" />
                </div>
                <div className="blog-para text-center">
                  <p>
                    Lorem Ipsum is simply & dummy text of the printing & it a
                    typesetting industry. Lorem Ipsum has been the & industry's
                    standard dummy text.
                  </p>
                </div>
                <div className="blog-colon text-center d-block">
                  <img src={colon} alt="" />
                </div>
                <div className="blog-colon1 text-center d-none">
                  <img src={colon1} alt="" />
                </div>
                <div className="blog-read text-center">
                  <h4 className="text-dark">Jeetesh Verma</h4>
                  <p className="text-dark">A Patient’s Son</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      <Link to="/Home/Testimonial">
      <div className="explore-btn">
          <span style={{color:"#000"}}>Want to See </span> More Review?{" "}
          <a className="learn-more" href="#">
            <img src={blog} alt="" className="up" />
          </a>
        </div>
      </Link>
      </div>
    </section>
  );
};

export default ReviewComponent;
