import React from "react";
import "./AboutComponent.css";
import imgIcon from "../../img/icon (21).png"
import img from "../../img/Image (4).png"
import { Link } from "react-router-dom";

const AboutComponent = () => {
  return (
    <section className="about" id="about">
      <div className="container-1240">
        <div className="row flex-wrap gy-2">
          <div className="col-xl-8  col-lg-12 col-md-12">
            <div className="about-content">
              <div className="sub-heading position-relative">
                <h4 className="text-dark fw-normal">Who we are</h4>
              </div>
              <div className="medium-heading">
                <h2 className="text-dark">We have advanced technology</h2>
              </div>
              <div className="about-para">
                <p className="text-dark">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </p>
              </div>
              <div className="vari-pass d-flex">
                <div className="best-service">
                  <h4>Our Mission</h4>
                  <p>
                    Lorem Ipsum is and simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum & has been the industry's
                    standard.
                  </p>
                </div>
                <div className="expert-team">
                  <h4>Our Vision</h4>
                  <p>
                    Lorem Ipsum is and simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum & has been the industry's
                    standard.
                  </p>
                </div>
              </div>
              <div className="btn-more d-flex align-items-center mb-2">
                <div className="more-btn">
                  <Link to="/Home/About_Us" className="blue-btn purple-btn text-white">
                    Know More
                  </Link>
                </div>
                <div className="talk-box d-flex align-items-center">
                  <div className="mobile-img">
                    <img src={imgIcon} alt="msg" />
                  </div>
                  <div className="contact-info">
                    <h6 className="fw-normal text-dark">Call Us on</h6>
                    <p className="fw-normal">+44 123 456 7890</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-12 col-md-12">
            <div className="about-img">
              <img src={img} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutComponent;
