import React from 'react'
import "./ContactComponent.css"
import map from "../../img/Map.png"

const ContactComponent = () => {
  return (
    <section className="contact-us">
    <div className="container-1240">
      <div className="bg-contact">
        <div className="row gy-3">
          <div className="col-xl-6 col-lg-6 col-md-12">
            <div className="right-set">
              <div className="content-text">
                <h4 className="text-dark">Get in Touch With Us</h4>
                <p className="right-para text-dark">Lorem Ipsum is simply & dummy text of the printing & it a typesetting
                  industry.</p>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="contact-information">
                    <h6 className="font16 h6">Contact Detail</h6>
                    <div className="contact-details">
                      <ul>
                        <li><a href="#" className="tel-icon text-dark position-relative"> +44 123 789 4560 </a></li>
                        <li><a href="#" className="email-icon text-dark position-relative"> troomedical@email.com </a>
                        </li>
                        <li><a href="#" className="map-icon text-dark position-relative">troomedical@email.com </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="opening-details">
                    <h6 className="font16 h6">Opening Hour</h6>
                    <div className="contact-para">
                      <p>Mon to Friday: 09:00 AM to 09:00 PM</p>
                      <p>Saturday: 10:00 AM to 02:00 PM</p>
                      <p>Sunday: Closed</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3671.8850349918985!2d72.49642611542339!3d23.027993021903114!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e9b278e046fa5%3A0xd837def63555f791!2sTRooInbound%20Private%20Limited!5e0!3m2!1sen!2sin!4v1674649284683!5m2!1sen!2sin"
                width="100%"
                height="300"
                style={{border:"0"}}
                allowfullscreen=""
                title="googleMap"
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12">
            <div className="form-bg position-relative">
              <div className="content-text">
                <h4 className="text-dark">Make an Appointment</h4>
                <p className="text-dark">Lorem Ipsum is simply & dummy text of the printing &
                  it a typesetting industry.</p>
              </div>
              <div className="blog-details-form cont-form">
                <form className="row g-3">
                  <div className="col-md-6">
                    <label for="inputEmail4" className="form-label">Full Name*</label>
                    <input type="email" className="form-control" id="inputEmail4" placeholder="John Doe"/>
                  </div>
                  <div className="col-md-6">
                    <label for="inputPassword4" className="form-label">Your Email*</label>
                    <input type="password" className="form-control" id="inputPassword4" placeholder="johndoe123@email.com"/>
                  </div>
                  <div className="col-md-6">
                    <label for="inputAddress" className="form-label">Phone Number*</label>
                    <input type="text" className="form-control" id="inputAddress" placeholder="+44 123 456 0789"/>
                  </div>
                  <div className="col-md-6">
                    <label for="inputState" className="form-label">Department*</label>
                    
                    <select name="drinks" className="country-select" required>
                      <option value="" disabled selected hidden>Cencer Department</option>
                      <option value="coffee">Cencer Department</option>
                      <option value="tea">Cencer Department</option>
                      <option value="milk">Cencer Department</option>
                    </select>
                  </div>
                  <div className="col-12">
                    <label for="exampleFormControlTextarea1" className="form-label">Messaage*</label>
                    <textarea className="form-control" id="exampleFormControlTextarea1" rows="3"
                      placeholder="write a meassage...."></textarea>
                  </div>
                  <div className="col-12">
                    <button type="submit" className="btn submit">Submit Form</button>
                  </div>
                </form>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default ContactComponent