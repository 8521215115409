import React from "react";
import cardio from "../../img/cardio-img.png"
import { FaFacebookF, FaLinkedinIn, FaTwitter  } from 'react-icons/fa';
import "./BlogsDetails.css"

const BlogDetailsCompo = () => {
  return (
    <section className="posting-blog">
      <div className="container-1240">
        <div className="row gy-3">
          <div className="col-lg-8">
            <div className="blog-details-left">
              <div className="medical-health">
                <a href="#">Medical Health</a>
              </div>
              <div className="health-title">
                How good is your cardiometabolic health & what is that, anyway?
              </div>
              <div className="posted-date">29 August, 2022 / Posted By: Admin</div>
              <p className="details-para">
                Lorem Ipsum is simply dummy text of the and this printing and
                typesetting industry. Lorem Ipsum has been the industry's
                standard dummy text and is a ever since the 1500s, when an
                unknown printer hwej took a galley of type and scrambled it to
                make a type specimen book. It has survived not only five this i
                s aw centuries, but also the leap into electronic typesetting,
                remaining essentially unchanged. It was popularised in please
                the 1960s with the release of Letraset sheets containing Lorem
                Ipsum passages, wait fot and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
              </p>
              <div className="inpatient-img">
                <img src={cardio} alt="" />
              </div>
              <div className="details-infor">
                <h4 className="text-dark">Lorem ipsume doller sit</h4>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and it
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard.
                </p>
                <ul className="health-item">
                  <li className="health-list">
                    Lorem Ipsum is simply dummy text of the printing and it
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard.
                  </li>
                  <li className="health-list">
                    Lorem Ipsum is simply dummy text of the printing and it
                    typesetting industry.{" "}
                  </li>
                  <li className="health-list">
                    Lorem Ipsum is simply dummy text of the printing and it
                    typesetting industry. Lorem Ipsum is simply dummy.
                  </li>
                  <li className="health-list">
                    Lorem Ipsum is simply dummy text of the printing.
                  </li>
                </ul>
                <h4 className="text-dark">Lorem ipsume doller sit</h4>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and it
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard.Lorem Ipsum is simply dummy text of the printing and
                  it typesetting industry. Lorem Ipsum has been the industry's
                  standard.Lorem Ipsum is simply dummy text of the printing and
                  it typesetting industry. Lorem Ipsum has been the industry's
                  standard.
                </p>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and it
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard.Lorem Ipsum is simply dummy text of the printing.
                </p>
                <h4 className="text-dark">Lorem ipsume doller sit</h4>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and it
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard.Lorem Ipsum is simply dummy text of the printing and
                  it typesetting industry. Lorem Ipsum has been the industry's
                  standard.Lorem Ipsum is simply dummy text of the printing and
                  it typesetting industry. Lorem Ipsum has been the industry's
                  standard.
                </p>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and it
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard.Lorem Ipsum is simply dummy text of the printing.
                </p>
              </div>
              <div className="post-border-top"></div>
              <div className="related-info d-flex align-items-center justify-content-between flex-wrap">
                <div className="related-tabs d-flex align-items-center">
                  <div className="related-tag font16">Related Tags:</div>
                  <ul className="nav nav-tabs nav-pills">
                    <li className="nav-item active">
                      <a className="nav-link" data-toggle="tab" href="#1a">
                        Healthcare
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" data-toggle="tab" href="#2a">
                        Medical
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" data-toggle="tab" href="#3a">
                        Doctor
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="share-on-media d-flex align-items-center">
                  <div className="share-on font16">Share on: </div>
                  <div className="share-icon">
                    <a href="#" className="facebook-icon">
                      <FaFacebookF/>
                    </a>
                    <a href="#" className="twitter-icon">
                      <FaTwitter/>
                    </a>
                    <a href="#" className="linkdine-icon">
                     <FaLinkedinIn/>
                    </a>
                  </div>
                </div>
              </div>
              <div className="post-border-btm"></div>
              <div className="blog-details-form">
                <form>
                  <div className="form-blog mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputName"
                      aria-describedby="emailHelp"
                      placeholder="Full Name"
                    />
                  </div>
                  <div className="form-blog mb-3">
                    <input
                      type="email"
                      className="form-control"
                      id="exampleInputPassword1"
                      placeholder="Email address"
                    />
                  </div>
                  <div className="form-blog mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputPassword1"
                      placeholder="Website"
                    />
                  </div>
                  <div className="form-blog mb-3">
                    <textarea
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      placeholder="Write a message....."
                    ></textarea>
                  </div>
                  <button type="submit" className="btn blog-details-btn">
                    Submit
                  </button>
                  <div className="mb-3 form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck1"
                    />
                    <label
                      className="form-check-label font14 text-dark"
                      for="exampleCheck1"
                    >
                      
                      save my name, email, and website in this browser for the
                      next time i comment.
                    </label>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="blog-details-right">
              <h4>Related Blogs</h4>
              <ul className="item-menu">
                <li className="list-menu">
                  <a href="#">Growth Across Varied Hospital Settings</a>
                </li>
                <li className="list-menu">
                  <a href="#">Hospitalist Impact on Patient Throughput</a>
                </li>
                <li className="list-menu">
                  <a href="#">
                    Coordinated EM Care Leads to Better Patient Care
                  </a>
                </li>
                <li className="list-menu">
                  <a href="#">
                    Improved Wait Times Lead to Patient Satisfaction
                  </a>
                </li>
                <li className="list-menu">
                  <a href="#">Innovation and Efficiency for a Pediatric ED</a>
                </li>
              </ul>
              <div className="case-btn">
                All Case Studies{" "}
                <a href="#">
                  <img src="img/blog-icon.png" alt="" />
                </a>
              </div>
              <div className="blog-post-wrap">
                <h4 className="text-white">Need Emergency?</h4>
                <div className="small-border"></div>
                <h3 className="dont-text text-white">
                  Don’t Be Hesitate To Contact Us
                </h3>
                <p className="post-para font14 text-white">
                  Lorem Ipsum is simply dummy text of the printing typesetting.
                </p>
                <div className="emergency-btn text-center">
                  <a href="#" className="text-white">
                    Emergency Cases
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogDetailsCompo;
