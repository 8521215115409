import React, { useState } from 'react'
import "./HighlightComponent.css"
import icon1 from "../../img/choose-icon1.png"
import icon2 from "../../img/choose-icon2.png"
import icon3 from "../../img/choose-icon3.png"
import icon4 from "../../img/choose-icon4.png"
import right from "../../img/choose-right.png"
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";

const HighlightComponent = () => {
  const [counter, setCounter] = useState(false);
  return (
    <section className="choose-us position-relative" id="choose-us">
    <div className="container-1240">
      <div className="row">
        <div className="col-xl-7 col-lg-12">
          <div className="choose-wrap">
            <div className="sub-heading position-relative">
              <h4 className="text-white fw-normal">Who we are</h4>
            </div>
            <div className="medium-heading">
              <h2>We have advanced technology</h2>
            </div>
            <div className="about-para">
              <p className="text-white">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                galley of type and scrambled it to make a type specimen book.</p>
            </div>
            <div className="invitation-box">
              <div className="row">
                <div className="col-xl-6 col-lg-12 col-md-12">
                  <h4 className="interv text-white fw-normal position-relative">Intervention Success</h4>
                  <h4 className="interv text-white fw-normal position-relative">Quality of Care Services</h4>
                  <h4 className="interv text-white fw-normal position-relative">Exclusive Support Team</h4>
                </div>
                <div className="col-xl-6 col-lg-12 col-md-12">
                  <h4 className="interv text-white fw-normal position-relative">Free Consultation</h4>
                  <h4 className="interv text-white fw-normal position-relative">Delivering world className Services</h4>
                  <h4 className="interv text-white fw-normal position-relative">Discounts & Updates</h4>
                </div>
              </div>
            </div>
            <div className="number-box">
              <div className="row exr-ss">
                <div className="col-xl-6 col-lg-12 col-md-12">
                  <div className="inner-number d-flex align-items-center">
                    <div className="massage-img"><img src={icon1} alt="msg"/></div>
                    <div className="massage-info">
                      <h4>
                      <ScrollTrigger
                        onEnter={() => setCounter(true)}
                        onExit={() => setCounter(false)}
                        className="fw-normal text-white"
                      >
                        {counter && (
                          <CountUp start={0} end={20} duration={3} delay={0} />
                        )}
                        <strong>+</strong>
                      </ScrollTrigger>
                      </h4>
                      <p className="fw-normal text-white">Year of Experience</p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-12 col-md-12">
                  <div className="inner-number d-flex align-items-center">
                    <div className="massage-img"><img src={icon2} alt="msg"/></div>
                    <div className="massage-info">
                      <h4>
                      <ScrollTrigger
                        onEnter={() => setCounter(true)}
                        onExit={() => setCounter(false)}
                        className="fw-normal text-white"
                      >
                        {counter && (
                          <CountUp start={10000} end={12050} duration={3} delay={0} />
                        )}
                        <strong>+</strong>
                      </ScrollTrigger>
                      </h4>
                      <p className="fw-normal text-white">Happy Patients</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row exr-ss">
                <div className="col-xl-6 col-lg-12 col-md-12">
                  <div className="inner-number d-flex align-items-center">
                    <div className="massage-img"><img src={icon3} alt="msg"/></div>
                    <div className="massage-info">                 
                      <h4>
                      <ScrollTrigger
                        onEnter={() => setCounter(true)}
                        onExit={() => setCounter(false)}
                        className="fw-normal text-white"
                      >
                        {counter && (
                          <CountUp start={1} end={125} duration={3} delay={0} />
                        )}
                        <strong>+</strong>
                      </ScrollTrigger>
                      </h4>
                      <p className="fw-normal text-white">Experienced Doctors</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="inner-number d-flex align-items-center">
                    <div className="massage-img"><img src={icon4} alt="msg"/></div>
                    <div className="massage-info">
                      <h4>
                      <ScrollTrigger
                        onEnter={() => setCounter(true)}
                        onExit={() => setCounter(false)}
                        className="fw-normal text-white"
                      >
                        {counter && (
                          <CountUp start={0} end={18} duration={3} delay={0} />
                        )}
                        <strong>+</strong>
                      </ScrollTrigger>
                      </h4>
                      <p class="fw-normal text-white">Number of Medical</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-5 col-lg-12">
          <div className="doctor-img">
            <img src={right} alt="" className="doct-img"/>
          </div>
        </div>
      </div>

    </div>
  </section>
  )
}

export default HighlightComponent