import React from 'react'
import Header from '../../Components/Header/Header'
import BannerGlobal from '../../Components/Banner/BannerGlobal'
import Footer from '../../Components/Footer/Footer'
import FAQComponent from '../../Components/FAQComponent/FAQComponent'
import useDocumentTitle from '../../PageTitle'

const FAQs = () => {
  useDocumentTitle("Medical | FAQs")
  return (
    <div>
        <Header/>
        <BannerGlobal/>
        <FAQComponent />
        <Footer/>
    </div>
  )
}

export default FAQs