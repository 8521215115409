export const HeaderData = [
  {
    title: "Home",
    path: "/Home",
  },
  {
    title: "About Us",
    path: "/Home/About_Us",
    heading: "Get Everything About Us",
  },
  {
    title: "Service",
    path: "#",
    subNav: [
      {
        subTitle: "Our Services",
        heading: " We Provie Best Services ",
        path: "/Home/Our_Services",
      },

      {
        subTitle: "Service Details",
        heading: "We Provide Best Primary Healthcare Service",
        path: "/Home/Our_Services/Service_Details",
      },
    ],
  },
  {
    title: "Pages",
    path: "#",
    subNav: [
      {
        subTitle: "Case Study",
        heading: "Hospital Case Studies",
        path: "/Home/Case_Study",
      },
      {
        subTitle: "Case Study Detail",
        heading: "Inpatient throughput improvements",
        path: "/Home/Case_Study/Case_Study_Detail",
      },
      {
        subTitle: "FAQ",
        heading: "Frequently asked questions",
        path: "/Home/FAQ",
      },
      {
        subTitle: "Our Team",
        heading: "Our expert doctors",
        path: "/Home/Our_Team",
      },
      {
        subTitle: "Testimonial",
        heading: "Clients review",
        path: "/Home/Testimonial",
      },
      {
        subTitle: "Error 404",
        heading: "Error_404",
        path: "/Home/Error_404",
      },
      {
        subTitle: "Comming Soon",
        heading: "Comming Soon",
        path: "/Home/Comming_Soon",
      },
    ],
  },
  {
    title: "Blogs",
    path: "#",
    subNav: [
      {
        subTitle: "Our Blogs",
        heading: "Read our latest blogs & articles",
        path: "/Home/Our_Blogs",
      },

      {
        subTitle: "Blog Details",
        heading: "Read our latest blogs & articles",
        path: "/Home/Our_Blogs/Blog_Details",
      },
    ],
  },
  {
    title: "Contact Us",
    path: "/Home/Contact_Us",
    heading: "Get in touch",
  },
  {
    title: "Make an Appointment",
    path: "/Home/Book_Appointment",
    heading: "Make an Appointment",
  },
];
