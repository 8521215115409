import React from 'react'
import caseDetailsImg from "../../img/inpatient-img1.png"
import blogIcon from "../../img/blog-icon.png"

const CaseStudyDetailsCompo = () => {
  return (
    <section className="posting-blog">
    <div className="container-1240">
      <div className="row">
        <div className="col-lg-9">
          <div className="blog-left">
            <div className="inpatient-img">
              <img src={caseDetailsImg} alt="" />
            </div>
            <h2>Inpatient Throughput Improvements</h2>
            <div className="blog-top-para">
              <h4>Lorem Ipsum is simy & dummy text of the printing & it typesetting postpond. Lorem Ipsum has been the
                industries the printing and it typesetting simply.</h4>
            </div>
            <div className="blog-middle-para">
              <p>Lorem Ipsum is simy & dummy text of the printing and is ok it typesetting postpond. Lorem Ipsum has
                been the industries the printing and it typesetting simply. standard dummy text ever. Lorem Ipsum is
                simy & dummy text of the printing & it typesetting postpond. Lorem Ipsum has been the industries the &
                printing and it typesetting simply. standard dummy text ever.Lorem Ipsum is simy & dummy text of the
                printing and is ok if it dummy text ever. Lorem Ipsum is simy & dummy text of the printing and it
                typesetting postpond. Lorem Ipsum has been the industries the printing and it typesetting simply.
                standard dummy text ever.</p>
            </div>
            <div className="healthcare-text">
              <h4>The Challenge</h4>
              <p>Lorem Ipsum is simy & dummy text of the printing and is ok it typesetting postpond. Lorem Ipsum has
                been the industries the printing and it typesetting simply. standard dummy te</p><br/>
              <p className="challenge-para">Lorem Ipsum is simy & dummy text of the printing and is ok it typesetting
                postpond. Lorem Ipsum has been the industries the printing and it typesetting simply. standard dummy
                text ever. Lorem Ipsum is simy & dummy text of the printing & it typesetting postpond. Lorem Ipsum has
                been the industries the & printing and it typesetting simply. standard dummy text ever.Lorem Ipsum is
                simy & dummy text of the printing and is ok if it dummy text ever. Lorem Ipsum is simy & dummy text of
                the printing and it typesetting postpond. Lorem Ipsum has been the industries the printing and it
                typesetting simply. standard dummy text ever.</p>
            </div>
            <div className="healthcare-text">
              <h4>The Solution</h4>
            </div>
            <div className="blog-last-para">
              <p>Lorem Ipsum is simy & dummy text of the printing and is ok it typesetting postpond. Lorem Ipsum has
                been the industries the printing and it typesetting simply. standard dummy te</p>
            </div>
            <div className="blog-middle-para">
              <p>Lorem Ipsum is simy & dummy text of the printing and is ok it typesetting postpond. Lorem Ipsum has
                been the industries the printing and it typesetting simply. standard dummy text ever. Lorem Ipsum is
                simy & dummy text of the printing & it typesetting postpond. Lorem Ipsum has been the industries the &
                printing and it typesetting simply. standard dummy text ever.Lorem Ipsum is simy & dummy text of the
                printing and is ok if it dummy text ever. Lorem Ipsum is simy & dummy text.</p>
            </div>

            <div className="invitation-box">
              <div className="row">
                <div className="col-lg-4">
                  <div className="healthcare-box">
                    <h4 className="interv text-white fw-normal position-relative">Intervention Success</h4>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="healthcare-box">
                    <h4 className="interv text-white fw-normal position-relative">Free Consultation</h4>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="healthcare-box">
                    <h4 className="interv text-white fw-normal position-relative">Discounts & Updates</h4>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="healthcare-box">
                    <h4 className="interv text-white fw-normal position-relative">Quality of Care Services</h4>
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="healthcare-box">
                    <h4 className="interv text-white fw-normal position-relative">Delivering world className Services</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="blog-btm-para">
              <p>Lorem Ipsum is simy & dummy text of the printing and is ok it typesetting postpond. Lorem Ipsum has
                been the industries the printing and it typesetting simply. standard dummy te</p>
            </div>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="blog-right">
            <h4>Other Case Study</h4>
            <ul className="item-menu">
              <li className="list-menu"><a href="#">Growth Across Varied Hospital Settings</a></li>
              <li className="list-menu"><a href="#">Hospitalist Impact on Patient Throughput</a></li>
              <li className="list-menu"><a href="#">Coordinated EM Care Leads to Better Patient Care</a></li>
              <li className="list-menu"><a href="#">Improved Wait Times Lead to Patient Satisfaction</a></li>
              <li className="list-menu"><a href="#">Innovation and Efficiency for a Pediatric ED</a></li>
            </ul>
            <div className="case-btn">
              All Case Studies <a href="#"><img src={blogIcon} alt="" className="up" /></a>
            </div>
            <div className="blog-post-wrap">
              <h4 className="text-white">Need Emergency?</h4>
              <div className="small-border"></div>
              <h3 className="dont-text text-white">Don’t Be Hesitate To
                Contact Us</h3>
              <p className="post-para font14 text-white">Lorem Ipsum is simply dummy text of the printing typesetting.</p>
              <div className="emergency-btn text-center">
                <a href="#" className="text-white">Emergency Cases</a>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </section>
  )
}

export default CaseStudyDetailsCompo