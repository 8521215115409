import React from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import CommingSoonComp from "../../Components/CommingSoonComp/CommingSoonComp";
import useDocumentTitle from "../../PageTitle";

const CommingSoon = () => {
  useDocumentTitle("Medical | Coming Soon")
  return (
    <div>
      <Header />
      <CommingSoonComp />
      <Footer />
    </div>
  );
};

export default CommingSoon;
