import React from "react";
import { BlogData } from "./BlogData";
import { Link, createSearchParams } from "react-router-dom";

const BlogComponent2 = () => {
  return (
    <section className="blog-medical">
      <div className="container-1240">
        <div className="row gy-3">
          <div className="col-lg-6">
            <div className="blog-right">
              {BlogData.slice(4, 7).map((e, i) => (
                <Link
                  to={`/Home/Our_Blogs/Blog_Details?${createSearchParams({
                    id: e.id,
                  })}`}
                >
                  <div className="blog-wrap d-flex" key={i}>
                    <div className="blog-post">
                      <img src={e.img} alt="blog2" />
                      <div className="date-btn">{e.date}</div>
                    </div>
                    <div className="blog-post-content">
                      <div className="blog-text">{e.name} </div>
                      <p>{e.para} </p>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
          <div className="col-lg-6">
            {BlogData.slice(7, 8).map((e, i) => (
              <div className="blog-left" key={i}>
                <div className="women-medical">
                  <img src={e.img} alt="wommen-medical" />
                </div>
                <div className="medical-text bg-white">
                  <h4>{e.name} </h4>
                  <p>{e.para} </p>
                  <div className="read-more">
                    <Link
                      to={`/Home/Our_Blogs/Blog_Details?${createSearchParams({
                        id: e.id,
                      })}`}
                    >
                      Read more <img src={e.arrow} alt="" className="up" />
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogComponent2;
