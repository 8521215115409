import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Routes/Home";
import About from "./Routes/About";
import Service from "./Routes/Service/Service";
import ServiceDetails from "./Routes/Service/ServiceDetails";
import CaseStudy from "./Routes/Pages/CaseStudy";
import CaseStudyDetails from "./Routes/Pages/CaseStudyDetails";
import FAQs from "./Routes/Pages/FAQs";
import Team from "./Routes/Pages/Team";
import Testimonial from "./Routes/Pages/Testimonial";
import Error404 from "./Routes/Pages/Error404";
import CommingSoon from "./Routes/Pages/CommingSoon";
import Blogs from "./Routes/Blogs/Blogs";
import BlogDetails from "./Routes/Blogs/BlogDetails";
import Contact from "./Routes/Contact";
import Appointment from "./Routes/Appointment";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/Home/About_Us" element={<About />} />
          <Route path="/Home/Our_Services" element={<Service/>} />
          <Route path="/Home/Our_Services/Service_Details" element={<ServiceDetails/>} />
          <Route path="/Home/Case_Study" element={<CaseStudy/>} />
          <Route path="/Home/Case_Study/Case_Study_Detail" element={<CaseStudyDetails/>} />
          <Route path="/Home/FAQ" element={<FAQs/>} />
          <Route path="/Home/Our_Team" element={<Team/>} />
          <Route path="/Home/Testimonial" element={<Testimonial/>} />
          <Route path="/Home/Error_404" element={<Error404/>} />
          <Route path="/Home/Comming_Soon" element={<CommingSoon/>} />
          <Route path="/Home/Our_Blogs" element={<Blogs/>} />
          <Route path="/Home/Our_Blogs/Blog_Details" element={<BlogDetails/>} />
          <Route path="/Home/Contact_Us" element={<Contact/>} />
          <Route path="/Home/Book_Appointment" element={<Appointment/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
