import React from "react";
import mapContact from "../../img/map-contact.png";
import telliphone from "../../img/tel-contact.png";
import email from "../../img/email-contact.png";
import linkdin from "../../img/black-linkdine.png";
import tweeter from "../../img/black-twitter.png";
import { FaFacebookF, FaLinkedinIn, FaTwitter  } from 'react-icons/fa';

const ContactComponent2 = () => {
  return (
    <section className="get-in-touch">
      <div className="container-1240">
        <div className="row gy-3">
          <div className="col-lg-6">
            <div className="we-help">
              <div className="font14 text-dark">How Can We Help?</div>
              <div className="help-title">
                <h3>We Are Ready to Help</h3>
              </div>
              <div className="social-icon-wrap d-flex align-items-center">
                <div className="phn-img pr-2">
                  <img src={mapContact} alt="mapContact" />
                </div>
                <div className="contact-no">
                  <h6 className="fw-normal lh-sm">
                    4516 School Street, Danbury,
                    <br />
                    CT, Canada
                  </h6>
                </div>
              </div>
              <div className="social-icon-wrap d-flex align-items-center">
                <div className="phn-img pr-2">
                  <img src={telliphone} alt="telliphone" />
                </div>
                <div className="contact-no">
                  <h6 className="fw-normal lh-sm">
                    +44 123 456 7890,
                    <br />
                    +44 7890 456 123
                  </h6>
                </div>
              </div>
              <div className="social-icon-wrap d-flex align-items-center">
                <div className="phn-img pr-2">
                  <img src={email} alt="email" />
                </div>
                <div className="contact-no">
                  <h6 className="fw-normal lh-sm">
                    example@email.com,
                    <br />
                    Dummy@email.com
                  </h6>
                </div>
              </div>
              <div className="msg-send d-flex align-items-center">
                <div className="sent-title">
                  <h6>We are on Social media also:</h6>
                </div>
                <div className="icon-social-media">
                  <a href="#">
                  <FaFacebookF/>
                  </a>
                  <a href="#">
                  <FaTwitter/>
                  </a>
                  <a href="#">
                  <FaLinkedinIn/>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <form className="contact-form row g-3">
              <div className="msg-title">
                <h3>Send a message</h3>
                <p>
                  Lorem Ipsum is simply & dummy text of the printing & it a
                  typesetting industry.
                </p>
              </div>
              <div className="col-md-6">
                <label for="inputEmail4" className="form-label">
                  Full Name*
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="inputEmail4"
                  placeholder="John Doe"
                />
              </div>
              <div className="col-md-6">
                <label for="inputPassword4" className="form-label">
                  Your Email*
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="inputPassword4"
                  placeholder="johndoe123@email.com"
                />
              </div>
              <div className="col-md-6">
                <label for="inputAddress" className="form-label">
                  Phone Number*
                </label>
                <input
                  type="tel"
                  className="form-control"
                  id="inputAddress"
                  placeholder="+44 123 456 0789"
                />
              </div>
              <div className="col-md-6">
                <label for="inputState" className="form-label">
                  Subject*
                </label>
                <select id="inputState" className="form-select">
                  <option selected>Choose...</option>
                  <option>...</option>
                </select>
              </div>
              <label for="exampleFormControlTextarea1" className="form-label">
                Massage*
              </label>
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
                placeholder="Write a message........."
              ></textarea>
              <div className="col-12">
                <button type="submit" className="btn form-submit">
                  submit form
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="contact-map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3671.8850349918985!2d72.49642611542339!3d23.027993021903114!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e9b278e046fa5%3A0xd837def63555f791!2sTRooInbound%20Private%20Limited!5e0!3m2!1sen!2sin!4v1674649284683!5m2!1sen!2sin"
          width="100%"
          height="600"
          style={{ border: "0" }}
          allowfullscreen=""
          title="googleMap"
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </section>
  );
};

export default ContactComponent2;
