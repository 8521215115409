import React from 'react'
import "./Banner.css"
import { Link } from 'react-router-dom'

const Banner = () => {
  return (
    <section className="banner" id="banner">
    <div className="container-1240">
      <div className="row">
        <div className="col-lg-6">
          <div className="b-right-content">
            <h3 className="text-white fw-normal">Welcome to <span className="troo">Troo Medical</span></h3>
            <h1 className="text-white fw-normal">Genuine commitment<br/>
              <span className="troo">to your health</span>
            </h1>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="b-left-content">
            <p className="text-white">Lorem ipsum dolor sit amet, consectetur Lorem & Lorem ipsum dolor sit amet,
              consectetur acing.Lorem ipsum dolor sit amet, & itself consectetur Lorem and Lorem ipsum dolor sit amet.
            </p>
            <div className="b-btn">
              <Link to="/Home/Our_Services" className="blue-btn text-white">See Our Services</Link>
              <Link to="/Home/About_Us" className="white-btn text-white">Learn About Us</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default Banner