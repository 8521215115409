import React from 'react'
import Header from '../Components/Header/Header'
import BannerGlobal from '../Components/Banner/BannerGlobal'
import Footer from '../Components/Footer/Footer'
import ContactComponent3 from '../Components/ContactComponent/ContactComponent3'
import useDocumentTitle from '../PageTitle'

const Appointment = () => {
  useDocumentTitle("Medical | Appointment")
  return (
    <div>
        <Header/>
        <BannerGlobal/>
        <ContactComponent3/>
        <Footer/>
    </div>
  )
}

export default Appointment