import caseStudy1 from "../../img/page1-img.png";
import caseStudy2 from "../../img/page2-img.png";
import caseStudy3 from "../../img/page3-img.png";
import caseStudy4 from "../../img/page4-img.png";
import caseStudy5 from "../../img/page5-img.png";
import caseStudy6 from "../../img/page6-img.png";


export const CaseStudyData = [
    {
        id : 1,
        name : "Hospitalist Medicine Hospitalist Impact on Patient Throughput",
        img : caseStudy1,
    },
    {
        id : 2,
        name : " Fostering Growth Across Varied Hospital Settings",
        img : caseStudy2,
    },
    {
        id : 3,
        name : "Inpatient Throughput Improvements",
        img : caseStudy3,
    },
    {
        id : 4,
        name : " Hospitalist Medicine Hospitalist Impact on Patient Throughput",
        img : caseStudy4,
    },
    {
        id : 5,
        name : "Heart Care Solutions",
        img : caseStudy5,
    },
    {
        id : 6,
        name : "Covid – 19 Vaccinations",
        img : caseStudy6,
    },
]