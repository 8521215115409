import React from 'react'
import "./Footer.css"
import logo from "../../img/troo-logo.png"
import icon from "../../img/icon (21).png"
import {
  FaTwitter,
  FaFacebookF,
  FaLinkedinIn,
  FaInstagram,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <section className="footer" id="footer">
    <div className="container-1240">
      <div className="row gy-4">
        <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
          <div className="first-box">
            <div className="ftr-logo"><a className="navbar-brand" href="#"><img src={logo} alt=""/></a></div>
            <div className="ftr-para">
              <h6 className="text-white">Lorem Ipsum is simy dummy and text of the printing typesetting Lorem Ipsum
                postpond.
                Lorem Ipsum is simy.</h6>
            </div>
            <div className="d-flex align-items-center">
              <div className="more-btn">
                <a href="#" className="blue-btn text-white">Know More</a>
              </div>
              <div className="talk-box d-flex align-items-center">
                <div className="mobile-img"><img src={icon} alt="msg"/></div>
                <div className="contact-info">
                  <h6 className="fw-normal text-white">Call Us on</h6>
                  <h6 className="font16 fw-normal text-white">+44 123 456 7890</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
          <div className="subcribe-wrap">
            <div className="subscribe">
              <h4 className="text-white"><span className="fw-bold">Subscribe</span> and
                get upteded Latest news.</h4>
            </div>
            <form>
              <div className="mb-3">
                <input type="email" className="ftr-email form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                  placeholder="Enter Email"/>
              </div>
              <button type="submit" className="btn ftr-submit text-white">Subscribe Now</button>
            </form>
          </div>
        </div>
        <div className="col-xl-2 col-lg-6 col-md-12 col-sm-12">
          <div className="navigation-te">
            <h4 className="text-white">Our Services</h4>
          </div>
          <div className="small-border"></div>
          <div className="ftr-items">
            <ul>
              <li><a href="#" className="text-white">Emergency Case</a></li>
              <li><a href="#" className="text-white"> Cancer Tritment</a></li>
              <li><a href="#" className="text-white">Heart Care</a></li>
              <li><a href="#" className="text-white"> Eye Care</a></li>
              <li> <a href="#" className="text-white"> Cardiology</a></li>
            </ul>
          </div>
        </div>
        <div className="col-xl-2 col-lg-6 col-md-12 col-sm-12">
          <div className="navigation-te">
            <h4 className="text-white">Navigation</h4>
          </div>
          <div className="small-border"></div>
          <div className="ftr-items">
            <ul>
              <li><a href="#" className="text-white">Home</a></li>
              <li><a href="#" className="text-white"> About Us</a></li>
              <li><a href="#" className="text-white">Blogs</a></li>
              <li><a href="#" className="text-white">Book an Appointment</a></li>
              <li> <a href="#" className="text-white">Contact With Us</a></li>
            </ul>
          </div>
        </div>
        <div className="ftr-btm-border"></div>
      </div>
      <div className="row gy-3 align-items-center">
        <div className="col-lg-3">
          <div className="social-media d-flex align-items-center">
            <div className="follow-us">
              <h6 className="text-white">Follow Us</h6>
            </div>
            <div className="social-icon px-2">
            <Link to="#">
                    <FaFacebookF />
                  </Link>
                  <Link to="#">
                    <FaTwitter />
                  </Link>
                  <Link to="#">
                    <FaLinkedinIn />
                  </Link>
                  <Link to="#">
                    <FaInstagram />
                  </Link>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="email text-white">
            © 2022 Medical Theme by <a href="#" className="emailid">Troo Theme.</a> All rights reserved
          </div>
        </div>
        <div className="col-lg-3">
          <div className="privacy-btn">
            <a href="#" className="font14 text-white">Terms & Conditions </a>
            <a href="#" className="font14 text-white">Privacy Policy</a>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default Footer