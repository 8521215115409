import React from 'react'
import Header from '../../Components/Header/Header'
import BannerGlobal from '../../Components/Banner/BannerGlobal'
import Footer from '../../Components/Footer/Footer'
import CaseStudyComponent from '../../Components/CaseStudyComponent/CaseStudyComponent'
import ContactComponent from '../../Components/ContactComponent/ContactComponent'
import useDocumentTitle from '../../PageTitle'

const CaseStudy = () => {
  useDocumentTitle("Medical | CaseStudy")
  return (
    <div className='caseStudyBG'>
        <Header/>
        <BannerGlobal/>
        <CaseStudyComponent/>
        <ContactComponent />
        <Footer/>
    </div>
  )
}

export default CaseStudy