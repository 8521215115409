import icon1 from "../../img/icon1.png";
import icon2 from "../../img/icon2.png";
import icon3 from "../../img/icon3.png";
import icon4 from "../../img/icon4.png";
import icon5 from "../../img/icon5.png";
import icon6 from "../../img/icon6.png";
import blogIcon from "../../img/blog-icon.png";

export const ServiceData = [
  {
    id: 1,
    name: "Primary Healthcare",
    img: icon1,
    arrow: blogIcon,
    para: "Lorem Ipsum is simy & dummy text of the printing & it typesetting postpond. Lorem Ipsum has been the industriesthe printing and it typesetting simply. standard dummy text ever.",
  },
  {
    id: 2,
    name: "Emergency Care",
    img: icon2,
    arrow: blogIcon,
    para: "Lorem Ipsum is simy & dummy text of the printing & it typesetting postpond. Lorem Ipsum has been the industriesthe printing and it typesetting simply. standard dummy text ever.",
  },
  {
    id: 3,
    name: "General Surgery",
    img: icon3,
    arrow: blogIcon,
    para: "Lorem Ipsum is simy & dummy text of the printing & it typesetting postpond. Lorem Ipsum has been the industriesthe printing and it typesetting simply. standard dummy text ever.",
  },
  {
    id: 4,
    name: "Dental Care",
    img: icon4,
    arrow: blogIcon,
    para: "Lorem Ipsum is simy & dummy text of the printing & it typesetting postpond. Lorem Ipsum has been the industriesthe printing and it typesetting simply. standard dummy text ever.",
  },
  {
    id: 5,
    name: "Nuerology",
    img: icon5,
    arrow: blogIcon,
    para: "Lorem Ipsum is simy & dummy text of the printing & it typesetting postpond. Lorem Ipsum has been the industriesthe printing and it typesetting simply. standard dummy text ever.",
  },
  {
    id: 6,
    name: "Nuerology",
    img: icon6,
    arrow: blogIcon,
    para: "Lorem Ipsum is simy & dummy text of the printing & it typesetting postpond. Lorem Ipsum has been the industriesthe printing and it typesetting simply. standard dummy text ever.",
  },
];
